import React, { useEffect } from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "./MenuItem";
import { models } from "../store/models";

const useStyles = makeStyles(() => ({
  rect: {
    borderRadius: "15px",
  },
}));

export default function Menu(props) {
  const { venue, loading, backButton } = props;
  const classes = useStyles();

  useEffect(() => {
    backButton();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  const placeHolders = [1, 2, 3, 4, 5];
  return (
    <main>
      {loading &&
        placeHolders.map((v, i) => (
          <Skeleton
            classes={classes}
            key={i}
            animation="wave"
            variant="rect"
            width={150}
            height={192}
          />
        ))}
      {!loading &&
        models.map((v, i) => (
          <MenuItem
            key={i}
            value={i}
            title={v.name}
            img={v.thumbnail}
            venue={venue}
          ></MenuItem>
        ))}
    </main>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles(() => ({
  rect: {
    borderRadius: "15px",
  },
}));

export default function Header(props) {
  const { venue, venueLogo, loading, showBack } = props;
  const backLink = `/${venue}`;

  const classes = useStyles();
  const logoWidth = venue === "fargo" ? "100px" : "110px";

  return (
    <header>
      {loading ? (
        <Skeleton
          classes={classes}
          animation="wave"
          variant="rect"
          width={150}
          height={80}
        />
      ) : (
        <>
          {showBack ? (
            <Link to={backLink} style={{ position: "absolute", left: "10px" }}>
              <ArrowBackIcon />
            </Link>
          ) : (
            <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
              <Link to={backLink}>
                <img src={venueLogo} alt="Logo" width={logoWidth}></img>
              </Link>
            </div>
          )}
        </>
      )}
    </header>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { makeStyles } from "@material-ui/core";
import { models } from "../store/models";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    height: "40px",
    minHeight: "40px",
    maxHeight: "40px",
  },
}));

export default function BackHeader(props) {
  const { venue } = props;
  let { id } = useParams();
  let intId = parseInt(id);

  const backLink = `/${venue}`;
  const classes = useStyles();
  const { name } = models[intId];

  return (
    <header className={classes.root}>
      <Link to={backLink} style={{ position: "absolute", left: "10px" }}>
        <ArrowBackIcon />
      </Link>
      <h2 style={{ fontFamily: "headerFont" }}>{name}</h2>
    </header>
  );
}

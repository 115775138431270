import React, { useState, useEffect } from "react";
import {
  Switch,
  Route,
  useParams,
  useRouteMatch,
  useLocation,
} from "react-router-dom";
import Header from "./Header";
import Menu from "./Menu";
import MenuItemDetail from "./MenuItemDetail";
import { SplashScreen } from "./SplashScreen";
import { venues } from "../store/models";
import useSound from "use-sound";
import BackHeader from "./BackHeader";

export default function VenueHome() {
  const { venue } = useParams();
  const location = useLocation();
  let venueData = venues.find((x) => x.venueId === venue);

  // State
  const [showBack, setShowBack] = useState(false);
  const [sound, setSound] = useState();
  const [venueLogo, setVenueLogo] = useState();
  const [showSplash, setShowSplash] = useState(true);

  const [play, { stop, isPlaying }] = useSound(sound, { interrupt: true });

  let { path } = useRouteMatch();

  const showBackButton = () => {
    setShowBack(true);
  };

  const hideBackButton = () => {
    setShowBack(false);
  };

  useEffect(() => {
    setTimeout(() => {
      console.log("done");
      setShowSplash(false);
    }, 6000);
  }, []);

  useEffect(() => {
    if (venueData) {
      const { venueName, venueLogoDark } = venueData;
      setVenueLogo(venueLogoDark);
      document.title = `${venueName} | AVC | 3D Cocktail Menu`;
    }
  }, [venueData]);

  useEffect(() => {
    if (isPlaying) {
      stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound]);

  useEffect(() => {
    const { pathname } = location;
    const { venueLogoDark, venueLogoLight } = venueData;

    if (pathname.indexOf("/", 1) > 1) {
      setVenueLogo(venueLogoLight);
    } else {
      setVenueLogo(venueLogoDark);
    }
  }, [location, venueData]);

  const handleSplashClose = () => {
    setShowSplash(false);
  };

  return (
    <>
      <SplashScreen
        open={showSplash}
        onClick={handleSplashClose}
        venueLogo={venueLogo}
        venue={venue}
      />

      <Switch>
        <Route exact path={path}>
          <Header
            venueLogo={venueLogo}
            loading={false}
            venue={venue}
            showBack={showBack}
          />
          <Menu loading={false} venue={venue} backButton={hideBackButton} />
        </Route>
        <Route path={`${path}/:id`}>
          <>
            <BackHeader venue={venue}></BackHeader>
            <MenuItemDetail
              backButton={showBackButton}
              orderLink={venueData.mrYumLink}
              soundValue={{ sound, setSound }}
              soundPlayer={{ play, stop, isPlaying }}
            />
          </>
        </Route>
      </Switch>
    </>
  );
}

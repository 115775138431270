import "./App.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import VenueHome from "./components/VenueHome";
import React, { useEffect } from "react";
import { fargo, wolfLane } from "./assets/images/logos";

function App() {
  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, []);
  const PlaceHolder = () => {
    const styles = {
      root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        flex: 1,
      },
      list: {
        listStyle: "none",
        padding: "0",
      },
      li: {
        width: "200px",
        height: "200px",
      },
      img: {
        width: "100%",
      },
    };

    return (
      <div style={styles.root}>
        <ul style={styles.list}>
          <li style={styles.li}>
            <Link to="/fargo">
              <img style={styles.img} src={fargo} alt="Fargo" />
            </Link>
          </li>
          <li style={styles.li}>
            <Link to="/wolf-lane">
              <img style={styles.img} src={wolfLane} alt="Wolf Lane" />
            </Link>
          </li>
        </ul>
      </div>
    );
  };

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <PlaceHolder />
          </Route>
          <Route path="/:venue">
            <VenueHome />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;

import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { models } from "../store/models";

export default function MenuItemDetail(props) {
  let { venue, id } = useParams();
  let intId = parseInt(id);
  const { backButton, orderLink, soundValue, soundPlayer } = props;
  const { setSound } = soundValue;
  const { play, isPlaying } = soundPlayer;

  const {
    modelUrl,
    cameraData,
    background,
    orderButton,
    orderButtonSmall,
    stub,
    soundLink,
    usdz,
    trackingId,
  } = models[intId];

  const styles = {
    itemDetail: {
      alignItems: "stretch",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      position: "relative",
      width: "100%",
      flex: "auto",
      backgroundImage: `url('${background}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      userSelect: "none",
    },
    buttonContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonOrder: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      backgroundColor: "black",
      color: "white",
    },
    buttonBack: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      width: "100%",
      backgroundColor: "rgb(215,215,215)",
      color: "black",
    },
  };

  useEffect(() => {
    document.body.style.backgroundColor = "white";
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);

    let trackingCode = `${venue}-${trackingId}`;

    fetch(
      `https://portal.viewa.com/api/v1/sites?cid=${trackingCode}&evt=pageload`
    ).then((response) => console.log("pageload tracked"));

    backButton();
    setSound(soundLink);
    return () => {
      document.body.style.backgroundColor = "black";
      document.body.style.overflow = "auto";
      setSound(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const touch = () => {
    if (!isPlaying) {
      play();
    }
  };

  const captureClick = (e) => {
    let trackingCode = `${venue}-${trackingId}`;
    fetch(
      `https://portal.viewa.com/api/v1/sites?cid=${trackingCode}&evt=Click`
    ).then((response) => console.log("click tracked"));
  };

  return (
    <>
      <div
        className="item-detail"
        style={styles.itemDetail}
        onTouchStart={touch}
      >
        <model-viewer
          src={modelUrl}
          ios-src={usdz}
          camera-target={cameraData}
          id="item-model-viewer"
          camera-controls
          auto-rotate
          environment-image="https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/spruit_sunrise_1k_HDR.hdr"
        ></model-viewer>
      </div>
      <div style={styles.buttonContainer}>
        <a
          href={`${orderLink}/${stub}`}
          target="_blank"
          rel="noopener noreferrer"
          onClickCapture={captureClick}
        >
          {orderButtonSmall ? (
            <picture
              style={{
                width: "100%",
              }}
            >
              <source
                media="screen and (max-height: 749px)"
                srcSet={orderButtonSmall}
                style={{
                  width: "100%",
                }}
              />
              <source
                media="screen and (min-height: 750px)"
                srcSet={orderButton}
                style={{
                  width: "100%",
                }}
              />
              <img
                src={orderButton}
                alt="Order Me"
                style={{
                  width: "100%",
                }}
              ></img>
            </picture>
          ) : (
            <img
              src={orderButton}
              style={{
                width: "100%",
              }}
              alt="Order Me"
            ></img>
          )}
        </a>
      </div>
    </>
  );
}

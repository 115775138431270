import React, { createContext } from "react";
import app from "firebase/app";

export const FirebaseContext = createContext();

const firebaseConfig = {
  apiKey: "AIzaSyBScvBbwIcBgFnG26OqO9TS4TaOSXPZ3_k",
  authDomain: "avc-3d-menu.firebaseapp.com",
  projectId: "avc-3d-menu",
  storageBucket: "avc-3d-menu.appspot.com",
  messagingSenderId: "216766805930",
  appId: "1:216766805930:web:436761343bebc60a9f5dd3",
  measurementId: "G-8GM1GFTYJ9",
};

export const FirebaseProvider = ({ children }) => {
  if (!app.apps.length) {
    app.initializeApp(firebaseConfig);
  }
  return (
    <FirebaseContext.Provider value={app}>{children}</FirebaseContext.Provider>
  );
};

import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function MenuItem(props) {
  const { value, img, venue, title } = props;
  const [explode, setExplode] = useState(0);
  const history = useHistory();

  const finishAnimation = () => {
    setExplode(0);
    history.push(`${venue}/${value}`);
  };

  return (
    <div
      className="item"
      onClick={() => setExplode(1)}
      onAnimationEnd={finishAnimation}
      explode={explode}
    >
      <img src={img} alt={title} height="304" width="336"></img>
    </div>
  );
}

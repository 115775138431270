import React, { useState, useEffect, useRef } from "react";

// Material UI
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
//import MusicNoteIcon from "@material-ui/icons/MusicNote";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
//import lenseLogo from "../assets/images/lense_logo.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  text: {
    color: "white",
    textAlign: "center",
    width: "60%",
  },
  root: {
    backgroundColor: "rgba(0, 0, 0)",
    justifyContent: "start",
    flexDirection: "column",
    paddingTop: "1rem",
  },
  logo: {
    padding: "1rem",
  },
  video: {
    width: "100%",
  },
  sound: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "0.5rem",
  },
}));

const isIOS = () => {
  return ["iPhone", "iPod", "iPad", "iPhone Simulator"].includes(
    navigator.platform
  );
};

export function SplashScreen(props) {
  const { open, onClick, venueLogo, venue } = props;
  const classes = useStyles();
  const [dimensions, setDimension] = useState([100, 100]);
  // eslint-disable-next-line no-unused-vars
  const [video, setVideo] = useState(
    "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/videos/blink.mp4"
  );

  const videoEl = useRef(null);

  useEffect(() => {
    if (videoEl) {
      videoEl.current.play().then((x) => {
        console.log("Playing");
      });
    }
  }, [videoEl]);

  useEffect(() => {
    if (venue === "fargo") {
      setDimension([100, 30]);
    }
  }, [venue]);

  return (
    <Backdrop
      classes={{ root: classes.root }}
      className={classes.backdrop}
      open={open}
      onClick={onClick}
    >
      <img
        src={venueLogo}
        alt="Venue Logo"
        width={dimensions[0]}
        height={dimensions[1]}
        className={classes.logo}
      />
      {isIOS() && (
        <div className={classes.sound}>
          <ArrowLeftIcon className="shake-horizontal" />
          <ArrowLeftIcon className="shake-horizontal" />
          <ArrowLeftIcon className="shake-horizontal" />
          <span>Turn your ringer on for the best experience</span>
        </div>
      )}

      <video
        width="537"
        height="471"
        playsInline
        autoPlay
        loop
        muted
        ref={videoEl}
        className={classes.video}
      >
        <source src={video} type="video/mp4" />
      </video>
      <div className={classes.text}>
        <h4>
          Experience our immersive cocktail menu and watch your cocktail come to
          life.
        </h4>
      </div>
    </Backdrop>
  );
}

export const models = [
  {
    stub: "garden-of-eden",
    name: "Garden of Eden",
    modelUrl:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-4v3.glb",
    background: "https://media.giphy.com/media/ZqlNOUN088cxo1Gh7B/giphy.gif",
    cameraData: "8.693m 59.67m -29.89m",
    thumbnail:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/cards/design2/GardenOfEden.png",
    orderButton:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/buttons/eden_button.png",
    orderLink:
      "https://www.mryum.com/lensofillusion/menu/main-menu/garden-of-eden",
    soundLink:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/sounds/garden_track.mp3",
    usdz:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-4v2.usdz",
    trackingId: "garden-of-eden",
  },
  {
    stub: "diablo-drop",
    name: "Diablo Drop",
    modelUrl:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-3.glb",
    background: "https://media.giphy.com/media/HgxIqYrLZHAcrmzKXF/giphy.gif",
    cameraData: "0m 0.7549m 0m",
    thumbnail:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/cards/design2/DiabloDrop.png",
    orderButton:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/buttons/diablo_button.png",
    orderLink:
      "https://www.mryum.com/lensofillusion/menu/main-menu/diablo-drop",
    soundLink:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/sounds/diablo_track.mp3",
    usdz:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-3.usdz",
    trackingId: "diablo-drop",
  },
  {
    stub: "candy-land",
    name: "Candy Land",
    modelUrl:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-1v2.glb",
    background: "https://media.giphy.com/media/ygsfebFc3FWqBNuSHs/giphy.gif",
    cameraData: "0m 1.2m 0m",
    thumbnail:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/cards/design2/CandyLand.png",
    orderButton:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/buttons/candy_button.png",
    orderLink: "https://www.mryum.com/lensofillusion/menu/main-menu/candy-land",
    soundLink:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/sounds/cotton_candy_track.mp3",
    usdz:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-1v2.usdz",
    trackingId: "candy-land",
  },
  {
    stub: "mobs-of-manhattan",
    name: "Mobs of Manhattan",
    modelUrl:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-5v2.glb",
    background: "https://media.giphy.com/media/9JWzMUoXChDqoxKahm/giphy.gif",
    cameraData: "0m 68.57m 0m",
    thumbnail:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/cards/design2/MobsOfManhattan.png",
    orderButton:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/buttons/mob_button.png",
    orderLink:
      "https://www.mryum.com/lensofillusion/menu/main-menu/mobs-of-manhattan",
    soundLink:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/sounds/mob_track.mp3",
    usdz:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-5v2.usdz",
    trackingId: "mobs",
  },
  {
    stub: "rumble-in-the-jungle",
    name: "Rumble in the Jungle",
    modelUrl:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-2.glb",
    background:
      //"https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/backgrounds/jungle_background.gif",
      "https://media.giphy.com/media/ty1Orku4vf2H7dZ0b4/giphy-downsized-large.gif",
    cameraData: "0.045m 1.029m 0.197m",
    thumbnail:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/cards/design2/RumbleInTheJungle.png",
    orderButton:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/buttons/jungle_button.png",
    orderButtonSmall:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/buttons/jungle_button_small.png",
    orderLink:
      "https://www.mryum.com/lensofillusion/menu/main-menu/rumble-in-the-jungle",
    soundLink:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/sounds/rumble_track.mp3",
    usdz:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/models/cocktail-modal-2.usdz",
    trackingId: "rumble",
  },
];

export const venues = [
  {
    venueName: "Wolf Lane",
    venueId: "wolf-lane",
    venueLogoLight:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/logos/wolf-lane-logo.png",
    venueLogoDark:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/logos/wolf-lane-logo-inverse.png",
    mrYumLink: "https://www.mryum.com/lensofillusion/menu/main-menu/",
  },
  {
    venueName: "Fargo",
    venueId: "fargo",
    venueLogoLight:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/logos/fargo-logo.png",
    venueLogoDark:
      "https://s3-ap-southeast-2.amazonaws.com/3dcocktails.viewa/logos/fargo-logo-inverse.png",
    mrYumLink: "https://www.mryum.com/lensofillusion/menu/main-menu/",
  },
];
